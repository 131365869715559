import React from 'react';

function LinkList({ links }) {
  return (
    <div className="Link-list flex flex-col items-center mt-2.5">
      {links.map((link, index) => (
        <a 
          key={index} 
          href={link.url} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="my-1.5"
        >
          <NeumorphismButton title={link.title} />
        </a>
      ))}
    </div>
  );
}

const NeumorphismButton = ({ title }) => {
  return (
    <button className="NeumorphismButton bg-[#0e5496] text-white text-lg py-3.5 px-7 w-[300px] rounded-lg shadow-[8px_8px_15px_#1b1f23,-8px_-8px_15px_#394045] transition transform hover:scale-105">
      <span>{title}</span>
    </button>
  );
};

export default LinkList;

import React, { useState, useEffect } from 'react'; // Importiere React, useState und useEffect
import './output.css'; // Tailwind CSS wird hier importiert
import supabase from './supabaseConfig';
import LinkList from './components/LinkList';

function App() {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data, error } = await supabase
        .from('Linktree')
        .select('*');

      if (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
        return;
      }

      setLinks(data);
    }

    fetchData();
  }, []);

  return (
    <div className="App bg-[#282c34] min-h-screen text-center relative">
      <header className="App-header flex flex-col items-center justify-center bg-[#282c34] min-h-screen text-white relative">
        <div
          className="round-image relative w-[100px] h-[100px] rounded-full bg-cover bg-center"
          style={{ backgroundImage: "url('./components/320x320.jpg')" }} // Passe den Bildpfad an
        ></div>
        <h1 className="mt-[90px] text-white text-[calc(10px+2vmin)]">
          Jan Cebulla
        </h1>
        <LinkList links={links} />
      </header>
    </div>
  );
}

export default App;
